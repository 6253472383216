import { getFunctions, httpsCallable } from 'firebase/functions'
import { DateTime } from 'luxon'
import { useState } from 'react'
import {
  SetSerialRetentionForPeriodParams,
  SetSerialRetentionForPeriodSchema,
  savedTimeWindow,
} from 'shared/apps/listen/types'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { Serial } from 'shared/types/utils'
import { ms } from 'shared/utils/time'
import { dateTimeFromISO } from 'shared/utils/timeFormat'
import { z } from 'zod'
import { Button } from './components/Button'
import { Dialog } from './components/Dialog'
import { Input } from './components/Input'
import { app } from './firebase'
import { set } from './firebaseMethods'
// import md5 from 'md5'

type Props = {
  serial: string
  currentTime: string
}

const functions = getFunctions(app, DEFAULT_REGION)
const setSerialRetentionForPeriod =
  httpsCallable<SetSerialRetentionForPeriodParams>(
    functions,
    'setSerialRetentionForPeriod',
  )

export function getSavedTimeWindowKey(
  serial: Serial,
  start_time: string,
  end_time: string,
): string {
  return `${serial}-${start_time}-${end_time}`
}

export function TimeWindowDialog({ serial, currentTime }: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [timeInterval, setTimeInterval] = useState<number>(2)
  const timeIntervalOptions = [
    { label: '2 heures', value: 2 },
    { label: '24 heures', value: 24 },
  ]

  const [cursorPosition, setCursorPosition] = useState<string>('center')
  const cursorPositionOptions = [
    { label: "Début de l'intervalle", value: 'before' },
    { label: "Milieu de l'intervalle", value: 'center' },
    { label: "Fin de l'intervalle", value: 'after' },
  ]

  const [comment, setComment] = useState<string>('')

  const handleValidate = async (
    currentTime: string,
    timeIntervalInHours: number,
    position: string,
  ) => {
    const timeInterval = ms(timeIntervalInHours, 'hour')

    const currentDate = dateTimeFromISO(currentTime)

    let start_time: DateTime | string
    let end_time: DateTime | string

    if (position === 'before') {
      start_time = currentDate.minus(timeInterval)
      end_time = currentDate
    } else if (position === 'after') {
      start_time = currentDate
      end_time = currentDate.plus(timeInterval)
    } else {
      start_time = currentDate.minus(timeInterval / 2)
      end_time = currentDate.plus(timeInterval / 2)
    }

    const payload: z.infer<typeof SetSerialRetentionForPeriodSchema> = {
      serials: {
        [serial]: [
          {
            start_date: start_time.toISO(),
            end_date: end_time.toISO(),
          },
        ],
      },
      origin: 'listen',
    }

    const formattedStartTime = start_time.toFormat("yyyy-MM-dd'T'HH:mm:ssZZ")
    const formattedEndTime = end_time.toFormat("yyyy-MM-dd'T'HH:mm:ssZZ")

    const savedTimeWindow: savedTimeWindow = {
      serial: serial,
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      comment: comment,
    }

    const key = getSavedTimeWindowKey(
      serial,
      formattedStartTime,
      formattedEndTime,
    )

    await set(`savedTimeWindow/${key}`, savedTimeWindow)

    setSerialRetentionForPeriod(payload)
  }

  return (
    <>
      <Button onClick={() => setIsDialogOpen(true)}>
        Voisinage intéressant
      </Button>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onValidate={() =>
          handleValidate(currentTime, timeInterval, cursorPosition)
        }
      >
        <div className="flex flex-col gap-2">
          <h2 className="font-semibold">Intervalle à conserver</h2>
          {timeIntervalOptions.map(({ label, value }) => (
            <div key={value}>
              <input
                type="radio"
                value={value}
                checked={timeInterval === value}
                onChange={() => setTimeInterval(value)}
              />
              <label className="ml-2">{label}</label>
            </div>
          ))}
          <h2 className="font-semibold">
            Position du curseur dans l'intervalle
          </h2>
          {cursorPositionOptions.map(({ label, value }) => (
            <div key={value}>
              <input
                type="radio"
                id={value}
                value={value}
                checked={cursorPosition === value}
                onChange={() => setCursorPosition(value)}
              />
              <label htmlFor={value} className="ml-2">
                {label}
              </label>
            </div>
          ))}
          <h2 className="mr-2">Commentaire :</h2>
          <Input
            id="commentInput"
            type="text"
            placeholder="Entrez un commentaire"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </Dialog>
    </>
  )
}
