// Make sure NOT to add a Luxon dependency in this file

type SingularTimeUnit = 'second' | 'minute' | 'hour' | 'day'
type PluralTimeUnit = `${SingularTimeUnit}s`
type TimeUnit = SingularTimeUnit | PluralTimeUnit

export function ms(value: number, unit: TimeUnit) {
  switch (unit) {
    case 'second':
    case 'seconds':
      return value * 1000
    case 'minute':
    case 'minutes':
      return value * 60 * 1000
    case 'hour':
    case 'hours':
      return value * 60 * 60 * 1000
    case 'day':
    case 'days':
      return value * 24 * 60 * 60 * 1000
  }
}

export function ms2sec(ms: number) {
  return ms / 1000
}

export function sec2ms(seconds: number) {
  return seconds * 1000
}

/** milliseconds to YYYY-MM-DD conversion */
export function tsToDate(ts: number) {
  return new Date(ts).toISOString().slice(0, 10)
}

export const unixEpoch = 0 // Jan 1st 1970
