import { z } from 'zod'
import { DeviceState } from '.'
import { Serial } from '../../types/utils'

export type DeviceHistory = DeviceState[]
export type DevicesHistory = Record<string, DeviceHistory>

// Milvus modes
export const MILVUS_MODES = [
  'all',
  'serialOnly',
  'facilityOnly',
  'serialAndRecordsSessionOnly',
] as const

export type MilvusMode = (typeof MILVUS_MODES)[number]

export const BUCKET_NAMES = [
  'oso-sounds',
  'oso-resp-sounds',
  'cp-children-sounds',
] as const

export type BucketName = (typeof BUCKET_NAMES)[number]

export const FetchSimilarParticlesSchema = z.object({
  particleRef: z.string(),
  mode: z.enum(MILVUS_MODES),
  bucket: z.enum(BUCKET_NAMES),
})

export type FetchSimilarParticlesParams = z.infer<
  typeof FetchSimilarParticlesSchema
>
export type FetchSimilarParticlesResponseData = string[]
export type BucketPrefix = string

export const SetSerialRetentionForPeriodSchema = z.object({
  serials: z.record(
    z.string(),
    z.array(
      z.object({
        start_date: z.string(),
        end_date: z.string(),
      }),
    ),
  ),
  origin: z.string(),
})

export type SetSerialRetentionForPeriodParams = z.infer<
  typeof SetSerialRetentionForPeriodSchema
>

export type savedTimeWindow = {
  serial: Serial
  startTime: string
  endTime: string
  comment: string
}
