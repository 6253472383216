import { DateTime, FixedOffsetZone } from 'luxon'
import { BucketName } from 'shared/apps/listen/types'
import { bucketShiftHour, dateTimeFromISO } from 'shared/utils/timeFormat'

type BucketConfig = {
  displayName: string
  startDate: (date: string) => DateTime
}

function isoUTCDate(date: string) {
  return dateTimeFromISO(date).setZone(FixedOffsetZone.utcInstance, {
    keepLocalTime: true,
  })
}

// startDate is used in a time zone agnostic Explorer context.
// Pick UTC to illustrate this
export const BUCKETS_CONFIG_MAP: Record<BucketName, BucketConfig> = {
  'oso-sounds': {
    displayName: 'PRODUCTION',
    startDate: (date) => isoUTCDate(date).plus({ hour: bucketShiftHour }),
  },
  'oso-resp-sounds': {
    displayName: 'PARIS-APHP - Service Pneumo',
    startDate: (date) => isoUTCDate(date),
  },
  'cp-children-sounds': {
    displayName: 'HOPITAL-DES-ARMEES-BREST - Enfants Paralysés Cérébraux',
    startDate: (date) => isoUTCDate(date),
  },
}
