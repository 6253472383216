import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { DatabaseSchema } from 'shared/apps/listen/databaseSchema'
import { DevicesHistory } from 'shared/apps/listen/types'
import { Centered } from 'shared/components/Centered'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { DateString, FacilityName, Room, Serial } from 'shared/types/utils'
import { bucketShiftHour, dateTimeFromISO } from 'shared/utils/timeFormat'
import { TimeZone } from 'shared/utils/timeZones'
import { collator } from 'shared/utils/web/collator'
import { Button } from './components/Button'
import { Input } from './components/Input'
import { Select } from './components/Select'
import { Selection } from './Explorer'
import { useMergedFirebase } from './hooks/useMergedFirebase'

type Props = {
  setSelection: (selection: Selection) => void
}

const refPathsMap = {
  devicesHistory: 'history/devices',
  facilities: 'facilities',
} as const

export function Path(props: Props) {
  const { data, loading, error } =
    useMergedFirebase<MergedType<typeof refPathsMap, DatabaseSchema>>(
      refPathsMap,
    )

  return loading ? (
    <Centered>Chargement...</Centered>
  ) : error ? (
    <Centered>{error.message}</Centered>
  ) : data.devicesHistory === null ? (
    <Centered>Aucun historique</Centered>
  ) : (
    <Path_
      devicesHistory={data.devicesHistory}
      facilities={data.facilities}
      {...props}
    />
  )
}

export function minDate() {
  // FIXME role not used correctly in old listen, acces to listen must be reviewed
  return '1900-01-01T00:00:00.000Z'
}

export function maxDate() {
  // Enable next day for time zones East of user's TZ
  return DateTime.now().plus({ day: 1 }).toISODate()
}

export function calculateTimestampRange(
  selectedDate: DateString,
  timeZone: TimeZone,
) {
  const extraRangeHours = 4 // take fleet update delay into account, add some margin

  const startDateTime = dateTimeFromISO(selectedDate)
    .setZone(timeZone, { keepLocalTime: true }) // keep midnight
    .plus({ hours: bucketShiftHour - extraRangeHours })

  const start = startDateTime.toMillis()
  const end = startDateTime.plus({ hours: 24 + 2 * extraRangeHours }).valueOf()

  return [start, end] as [number, number]
}

type SerialRoomAndFacility = Record<
  string,
  {
    room: Room
    facility: string
  }
>
type FacilitySerials = Record<FacilityName, Set<Serial>>

function defaultSerial(
  serialRoomAndFacility: SerialRoomAndFacility,
  facilitySerials: FacilitySerials,
) {
  const pathParts = window.location.pathname.split('/')
  const serial = pathParts[1]

  const serials = Object.keys(serialRoomAndFacility)
  if (serials.includes(serial)) return serial

  if (serial.length > 0) {
    window.alert(`Numéro de série inconnu : ${serial}`)
    const facility = 'Établissement inconnu'
    serialRoomAndFacility[serial] = { room: 'Pas de chambre', facility }
    facilitySerials[facility] = new Set([serial])
    return serial
  }

  return serials[0]
}

function defaultDate() {
  const pathParts = window.location.pathname.split('/')
  if (pathParts.length > 2) {
    const dateString = pathParts[2]
    const date = dateTimeFromISO(dateString)
    if (date.isValid && dateString <= maxDate() && dateString >= minDate())
      return dateString
    console.error(`Date invalide : ${dateString}`)
  }

  return DateTime.now().toISODate()
}

function Path_({
  setSelection: setBucketPrefix,
  devicesHistory,
  facilities,
}: Props & {
  devicesHistory: DevicesHistory
  facilities: Record<FacilityName, TimeZone>
}) {
  const [selectedDate, setSelectedDate] = useState<string>(defaultDate())

  useEffect(() => {
    // Init from URL
    setSelectedDate(defaultDate())
  }, [])

  const { serialRoomAndFacility, facilitySerials } = useMemo(() => {
    const serialRoomAndFacility: SerialRoomAndFacility = {}
    const facilitySerials: FacilitySerials = {}

    const timeRangeCache: Partial<Record<TimeZone, [number, number]>> = {}

    for (const serial in devicesHistory) {
      const deviceHistory = Object.values(devicesHistory[serial])

      for (const { room, facility, startDate, endDate } of deviceHistory) {
        const timeZone = facilities[facility]
        let timeRange = timeRangeCache[timeZone]
        if (timeRange === undefined) {
          timeRange = calculateTimestampRange(selectedDate, timeZone)
          timeRangeCache[timeZone] = timeRange
        }
        const [start, end] = timeRange
        if ((!endDate || endDate >= start) && startDate <= end) {
          // FIXME seul le dernier état connu intersectant l'intervalle de ~1 jour
          // est conservé. Il peut y en avoir plusieurs, si changement
          // de chambre ou d'éts ce jour là
          serialRoomAndFacility[serial] = { room, facility }
          facilitySerials[facility] ??= new Set()
          facilitySerials[facility].add(serial)
        }
      }
    }

    return { serialRoomAndFacility, facilitySerials }
  }, [selectedDate, devicesHistory, facilities])

  const [selectedSerial, setSelectedSerial] = useState<string>(
    defaultSerial(serialRoomAndFacility, facilitySerials),
  )

  // Correct selected serial upon date change if necessary
  useEffect(() => {
    if (!serialRoomAndFacility[selectedSerial]) {
      setSelectedSerial(Object.keys(serialRoomAndFacility)[0])
    }
  }, [serialRoomAndFacility, selectedSerial])

  interface ValidatedState {
    date: DateString
    serial: Serial
    facility: FacilityName // inferred from the two others, but needed to set timeZone
  }

  function createValidatedState(serial: Serial, date: DateString) {
    return { date, serial, facility: serialRoomAndFacility[serial].facility }
  }

  const [validatedState, setValidatedState] = useState<ValidatedState>(
    createValidatedState(selectedSerial, selectedDate),
  )

  useEffect(() => {
    setBucketPrefix({
      serial: validatedState.serial,
      date: validatedState.date,
      timeZone: facilities[validatedState.facility],
      prefix: `${validatedState.serial}/${validatedState.date}`,
    })
  }, [validatedState, setBucketPrefix, facilities])

  function prevDate() {
    if (selectedDate > minDate())
      setSelectedDate(
        dateTimeFromISO(selectedDate).minus({ day: 1 }).toISODate(),
      )
  }

  function nextDate() {
    if (selectedDate < maxDate())
      setSelectedDate(
        dateTimeFromISO(selectedDate).plus({ day: 1 }).toISODate(),
      )
  }

  function validate() {
    setValidatedState(createValidatedState(selectedSerial, selectedDate))
    window.history.replaceState(null, '', `/${selectedSerial}/${selectedDate}`)
  }

  const sortedFacilitySerials = useMemo(() => {
    return [
      ...facilitySerials[serialRoomAndFacility[selectedSerial].facility],
    ].sort((serialA, serialB) =>
      collator.compare(
        serialRoomAndFacility[serialA].room,
        serialRoomAndFacility[serialB].room,
      ),
    )
  }, [facilitySerials, serialRoomAndFacility, selectedSerial])

  const isValidatedState =
    selectedDate === validatedState.date &&
    selectedSerial === validatedState.serial

  return (
    <div className="flex flex-row gap-4">
      <div className="flex gap-2">
        <Button onClick={prevDate} disabled={selectedDate <= minDate()}>
          &larr;
        </Button>
        <Input
          value={selectedDate}
          type="date"
          min={minDate()}
          max={maxDate()}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
        <Button onClick={nextDate} disabled={selectedDate >= maxDate()}>
          &rarr;
        </Button>
      </div>
      <Select
        onChange={(event) => {
          const selectedFacility = event.target.value
          const validSerials = [...facilitySerials[selectedFacility]].filter(
            (serial) =>
              serialRoomAndFacility[serial].facility === selectedFacility,
          )
          if (validSerials.length === 0)
            alert(`No serial found for facility ${selectedFacility}`)
          setSelectedSerial(validSerials[0])
        }}
        value={serialRoomAndFacility[selectedSerial].facility}
      >
        {Object.keys(facilitySerials)
          .sort()
          .map((facility) => (
            <option key={facility} value={facility}>
              {facility}
            </option>
          ))}
      </Select>
      <Select
        onChange={(event) => setSelectedSerial(event.target.value)}
        value={selectedSerial}
      >
        {sortedFacilitySerials.map((serial) => {
          return (
            <option key={serial} value={serial}>
              {serialRoomAndFacility[serial].room ?? '?'} ({serial.slice(-4)})
            </option>
          )
        })}
      </Select>
      <Button disabled={isValidatedState} onClick={validate}>
        Valider
      </Button>
    </div>
  )
}
