import type { FirebaseApp } from 'firebase/app'
import { FC, useCallback, useState } from 'react'
import { useTranslateParticle } from 'shared/hooks/useTranslateParticle'
import { BucketName, LANGUAGE_OPTIONS } from 'shared/types/azureSpeech'
import { ButtonProps } from './Button'
import { DropdownProps } from './DropdownCheckboxes'

interface TranslationSectionProps {
  particleRef: string
  bucket: BucketName
  Button: React.FC<ButtonProps>
  DropdownCheckboxes: FC<DropdownProps>
  app: FirebaseApp
  compact?: boolean
}

export const TranslationSection: FC<TranslationSectionProps> = ({
  particleRef,
  bucket,
  Button,
  DropdownCheckboxes,
  app,
  compact = false,
}) => {
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([
    'fr-FR',
  ])

  const {
    speechResult,
    translateParticle,
    loading: speechProcessingLoading,
  } = useTranslateParticle(app)

  const handleLanguageChange = useCallback((languages: string[]) => {
    setSelectedLanguages(
      languages.map((name) => {
        const option = LANGUAGE_OPTIONS.find((opt) => opt.name === name)
        return option ? option.code : name
      }),
    )
  }, [])

  const handleSpeechProcessing = useCallback(() => {
    if (particleRef) {
      translateParticle(particleRef, bucket, selectedLanguages)
    }
  }, [particleRef, bucket, selectedLanguages, translateParticle])

  return (
    <div className="p-2">
      <div className="flex flex-row items-center gap-5">
        <Button
          title="Transcription et Traduction"
          className="h-8 px-3 py-1 text-sm"
          onClick={handleSpeechProcessing}
          disabled={speechProcessingLoading || !particleRef}
        >
          {speechProcessingLoading
            ? 'Traitement...'
            : 'Transcription et Traduction'}
        </Button>

        <DropdownCheckboxes
          options={LANGUAGE_OPTIONS.map((lang) => lang.name)}
          value={selectedLanguages.map((code) => {
            const lang = LANGUAGE_OPTIONS.find((l) => l.code === code)
            return lang ? lang.name : code
          })}
          onValidation={handleLanguageChange}
          resetValue={['Français']}
          disabled={speechProcessingLoading}
          maxSelections={4}
          buttonClassName="px-3 py-1 text-sm h-8 flex flex-row items-center gap-5"
        >
          sélection d'une liste de langues sources
        </DropdownCheckboxes>

        {speechProcessingLoading && (
          <div className="animate-spin text-sm">⏳</div>
        )}
      </div>

      {/* Display speech results */}
      {!speechProcessingLoading && speechResult && (
        <div className="mt-2 rounded-lg p-3 text-sm text-white">
          {speechResult.originalText.trim() ? (
            <div>
              {compact ? (
                <div className="flex flex-col space-y-2">
                  <div>
                    <span className="font-semibold">
                      Transcription ({speechResult.detectedLanguage}):
                    </span>{' '}
                    {speechResult.originalText}
                  </div>
                  {speechResult.translatedText && (
                    <div>
                      <span className="font-semibold">Traduction:</span>{' '}
                      {speechResult.translatedText}
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="font-bold">
                    Transcription ({speechResult.detectedLanguage}):
                  </div>
                  <div className="mb-2">{speechResult.originalText}</div>
                  {speechResult.translatedText && (
                    <>
                      <div className="font-bold">Traduction:</div>
                      <div className="mb-2">{speechResult.translatedText}</div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="font-medium text-amber-400">
              La detection de la parole a échoué.
            </div>
          )}
        </div>
      )}
    </div>
  )
}
