import React from 'react'
import {
  AlertType,
  alertTypeColor,
  alertTypeString,
} from 'shared/apps/live/alert'
import { verticalBlockStyle } from 'shared/components/Predictions'
import { cn } from 'shared/utils/web/cn'
import { SOUND_DURATION } from '../Explorer'
import { FormattedAlerts } from '../hooks/useAlerts'

type AlertsVisualizerProps = {
  alerts: FormattedAlerts
  start: number
  end: number
  className?: string
}

function isAlertType(type: string): type is AlertType {
  return Object.keys(alertTypeColor).includes(type)
}

export const AlertsVisualizer: React.FC<AlertsVisualizerProps> = ({
  alerts,
  start,
  end,
  className,
}) => (
  <div className={`relative ${className ?? ''}`}>
    {Object.keys(alerts).map((rawTimestamp) => {
      const ts = parseInt(rawTimestamp, 10)

      const left = Math.max(ts - SOUND_DURATION, start)
      const right = Math.min(ts, end)

      if (!alerts[ts] || right < left) return null

      const alert = alerts[ts]

      const validatedTooltip = `Alertes validées : ${alerts[ts].validatedTypes
        ?.filter((type) => isAlertType(type))
        .map((type, index) => {
          const delay = alerts[ts].delays[index]
          return `${alertTypeString[type]} (${delay})`
        })
        .join(', ')} \n`
      const recordedTooltip = `Alertes enregistrées : ${alerts[ts].recordedTypes
        ?.filter((type) => isAlertType(type))
        .map((type, index) => {
          const comment = alerts[ts].comments[index]
          return `${alertTypeString[type]} (${comment})`
        })
        .join(', ')}`

      const tooltip = `${
        alerts[ts].validatedTypes.length > 0 ? validatedTooltip : ''
      }${alerts[ts].recordedTypes.length > 0 ? recordedTooltip : ''}`

      return (
        <div
          key={ts}
          title={tooltip}
          className="absolute flex items-center justify-center"
          style={{
            ...verticalBlockStyle(left, right, start, end - start),
          }}
        >
          <div
            className={cn(
              'h-3 w-3 rounded-full',
              (alerts[ts]?.validatedTypes ?? []).length > 0
                ? `border-2 border-white ${alertTypeColor[alert.validatedTypes[0]] ?? ''}`
                : `bg-yellow-300 ${alertTypeColor[alerts[ts].recordedTypes[0]] ?? ''}`,
            )}
          />
        </div>
      )
    })}
  </div>
)
