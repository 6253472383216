import { getFunctions, httpsCallable } from 'firebase/functions'
import { useCallback, useState } from 'react'
import {
  BucketName,
  FetchSimilarParticlesParams,
  FetchSimilarParticlesResponseData,
  MilvusMode,
} from 'shared/apps/listen/types'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { onError } from 'shared/utils/web/error'
import { app } from '../firebase'

const functions = getFunctions(app, DEFAULT_REGION)
const fetchSimilarParticles = httpsCallable<
  FetchSimilarParticlesParams,
  FetchSimilarParticlesResponseData
>(functions, 'fetchSimilarParticles')

export type SimilarParticles = string[]

export const useMilvus = (mode: MilvusMode, selectedBucket: BucketName) => {
  const [similarParticles, setSimilarParticles] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const searchSimilarParticles = useCallback(
    async (particle: string) => {
      const cleanParticle = particle.replace('.ogg', '')
      setSimilarParticles([])

      setLoading(true)

      await fetchSimilarParticles({
        particleRef: cleanParticle,
        mode: mode,
        bucket: selectedBucket,
      })
        .then((result) => {
          const newSimilarParticles = result.data
          setSimilarParticles(newSimilarParticles)
        })
        .catch(onError)
      setLoading(false)
    },
    [mode, selectedBucket],
  )

  return {
    similarParticles,
    searchSimilarParticles,
    milvusLoading: loading,
  }
}
